<template>
  <main class="content">
    <Hero title="Inloggen"/>
    <form class="block maxw--neck form" @submit.prevent="submit">
      <p class="text--center mb-2">
        Welkom bij ons ledenportaal, waar we interne documenten van onze vereniging delen. Dit gedeelte is alleen toegankelijk voor geregistreerde leden. Als u lid bent maar nog geen account heeft, kunt u eenvoudig een aanvraag indienen. Binnen enkele dagen ontvangt u een link om uw registratie te voltooien.
      </p>
      <div class="form__row">
        <FormTextField name="email" type="email" label="Email" v-model="v$.email.$model" :validator="v$.email"/>
      </div>
      <div class="form__row">
        <FormTextField name="password" type="password" label="Wachtwoord" v-model="v$.password.$model" :validator="v$.password"/>
      </div>
      <div class="form__row">
        <div class="field">
          <button class="btn btn--full mt-1" type="submit" :disabled="isLoading">
            Inloggen
            <Spinner v-if="isLoading" />
          </button>
        </div>
      </div>
      <div class="vertical-center">
        <nuxt-link to="/wachtwoord-vergeten" class="btn btn--flat">Wachtwoord vergeten?</nuxt-link>
        <nuxt-link to="/registreren" class="btn btn--flat">Nog geen account?</nuxt-link>
      </div>
    </form>
  </main>
</template>

<script lang="ts" setup>
import useVuelidate, { type ServerErrors } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

useHead({
  title: 'Inloggen'
});

definePageMeta({
  pageTransition: pageTransitionConfig,
});

const { isLoading, startLoading, stopLoading } = useLoading();
const { login } = useStrapiAuth();
const router = useRouter();

const formData = reactive({
  email: '',
  password: ''
});

const $externalResults = ref<ServerErrors>({});

async function submit(e: SubmitEvent) {
  if (!await v$.value.$validate() || isLoading.value) return;

  startLoading();

  try {
    await login({ identifier: formData.email, password: formData.password });

    router.push('/');
  } catch (e: any) {
    const errors = [];

    if (e?.error?.name === "ValidationError") {
      errors.push('Ongeldig e-mailadres of wachtwoord');
    }

    $externalResults.value = {
      password: errors
    }
  } finally {
    stopLoading();
  }
}

const rules = computed(() => ({
  email: {
    required: helpers.withMessage('Dit veld is verplicht', required)
  },
  password: {
    required: helpers.withMessage('Dit veld is verplicht', required)
  }
}));

const v$ = useVuelidate(rules, formData, { $externalResults });
</script>
